/**
 * setting of the API server to point the application to. qaadmin for staging. admin for prod.
 * @type {string}
 */
export const API_SERVER = 'https://qaadmin.conferenceondemand.net';

export const API = {
    loginAPI: `${API_SERVER}/api/siteLogin.php`,
    reauthenticateAPI: `${API_SERVER}/api/reAuthenticate.php`,
    logoutAPI: `${API_SERVER}/api/logoutUser.php`,
    logEventAPI: `${API_SERVER}/api/logEvent.php`,
    sessionVerification: `${API_SERVER}/api/userSessionVerification.php`,
    getUserProductAccess: `${API_SERVER}/api/getUserProductsByEvent.php`,
    eventUrl: `${API_SERVER}/api/getEvents.php`,
    contactAPI: `${API_SERVER}/api/submitContact.php`,
    eventProductsUrl: `${API_SERVER}/api/getProductsByEvent.php`,
    speakerAPI: `${API_SERVER}/api/getSpeakerBio.php`,

    // Chat APIs
    chatAliveAPI: `${API_SERVER}/api/isGroupChatActive.php`,
    sessionChatURL: `${API_SERVER}/api/getChatsBySession.php`,
    trackChatURL: `${API_SERVER}/api/getChatsByTrack.php`,

    presentationMediaURL: `${API_SERVER}/presentation_media`,
    videoURL: `${API_SERVER}/video`,

    // account APIs
    postAccountInfoURL: `${API_SERVER}/api/postEventUserInfo.php`,
    validateEmailURL: `${API_SERVER}/api/validateEmail.php`,
    sendResetPasswordEmailAPI: `${API_SERVER}/api/sendResetPasswordEmail.php`,
    executePasswordUpdateAPI: `${API_SERVER}/api/updatePassword.php`,
    testResetPasswordAPI: `${API_SERVER}/api/testResetPassword.php`,

    // Session APIs
    sessionAPI: `${API_SERVER}/api/getSessionData.php`,
    userproductUrl: `${API_SERVER}/api/getUserProductBySession.php`,
    sessionInfo: `${API_SERVER}/api/getSessionInfo.php`,

    eventSessionsAPI: `${API_SERVER}/api/getEventSessions.php`,
    registerAPI: `${API_SERVER}/api/updateUser.php`,
    statusAPI: `${API_SERVER}/api/status.php`,
    clientPage: `${API_SERVER}/client`,
    chatPostAPI: `${API_SERVER}/api/postMessage.php`,
    forgotPasswordAPI: `${API_SERVER}/api/forgotPassword.php`,
    socketAPI: 'https://codchat.herokuapp.com',
    submitContactAPI: `${API_SERVER}/api/submitHomePageContact.php`,
    contactUrl: `${API_SERVER}/api/submitContact.php`,

    // TradeShow
    tradeShowAPI: `${API_SERVER}/api/getTradeShow.php`,
    boothContactAPI: `${API_SERVER}/api/submitBoothContact.php`,

    //stripeAPI: `pk_live_nc76k2Yz0Ahs4YfjnuVLSOtk`, ///// LIVE
    stripeAPI: `pk_test_eNNawOuYuksesqBh913rDoqb`, ///// TEST
    stripeIntentAPI: `${API_SERVER}/api/stripeIntent.php`,
    validatePromoAPI: `${API_SERVER}/api/validatePromo.php`,

    geoKEY: 'dXBVaXJwdTV4SDd2U3ZDVHBScmxBTHNJbXdqT2J3bXdkaWNNOWprMw==',
};

import React, {useContext} from "react";
import './SelectRegistrationOption.scss';
import {ProductCard} from "./Payment/ProductCard";
import {EventContext} from "../contexts/EventContext";
import {ProductType} from "../helpers/ProductHelper";
import {useNavigate} from "react-router-dom";

export function SelectRegistrationOption(props) {
    const {
        show,
        onCancel,
        onProceedToRegistration,
        onRedirectToSessionListing,
    } = props;

    const { allProductsForEvent, event } = useContext(EventContext);
    const conferenceProduct = allProductsForEvent.filter((prod) => prod.product_id === ProductType.Conference);
    const navigate = useNavigate();

    const handleSessionTrackOption = () => {
        navigate(event.alias);
        onRedirectToSessionListing();
    };

    const cancelDisplay = (e) => {
        if (e.target.className.includes('SelectRegistrationOption')) {
            onCancel();
        }
    }
    const confProduct = {
        ...conferenceProduct,
        customName: 'Register for Full Conference',
        customDescription: `Click this option to register for full access to all session and tracks`,
    };
    const sessionProduct = {
        customName: 'Register for Individual Sessions or Tracks',
        cost: null,
        customDescription: 'Click this option to browse sessions/tracks and choose what you wish to register for.'
    };

    return (
        <div className={`SelectRegistrationOption ${show ? 'show' : ''}`}
            onClick={cancelDisplay}
        >
            <div>
                <ProductCard
                    product={confProduct}
                    onClick={onProceedToRegistration}
                />
                <ProductCard
                    product={sessionProduct}
                    onClick={handleSessionTrackOption}
                />
            </div>
        </div>
    )
}
